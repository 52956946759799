import { RewardTarget } from '@sasagase/types';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { uri } from '../../../lib';

interface ExclusionOrderFormProps  {
	targets: RewardTarget[];
	onChangeOrderNumber: ( orderNumber: string) => void;
	onChangeCanSendMail: (target: RewardTarget, canSendMail: boolean) => void;
	onFilterTarget: (orderId: string) => void;
}

export function ExclusionOrderForm(props: ExclusionOrderFormProps): React.ReactElement {
	const { register, getValues, watch } = useForm({
		defaultValues: {
			'orderNumber': '',
		},
	});

	const handleClickChangeOrderNumber = () => {
		const orderNumber = getValues('orderNumber');
		if (!orderNumber) {
			return;
		}
		props.onChangeOrderNumber(orderNumber);
	};

	const handleChangeCanSendMail = (target: RewardTarget) => (ev: React.ChangeEvent<HTMLInputElement>) => {
		props.onChangeCanSendMail(target, ev.currentTarget.checked);
	};

	const toRMSOrderDetailURL = (orderNumber: string) => uri`https://order-rp.rms.rakuten.co.jp/order-rb/individual-order-detail-sc/init?orderNumber=${orderNumber}`;

	const orderNumber = watch('orderNumber');

	return (
		<div className="bl_col bl_col__4">
			<div className="bl_panel bl_panel__ttlInv">
				<h2 className="el_lv2Heading">注文番号から設定</h2>
				<div className="bl_panel_row mb_24">
					<h3 className="el_lv3Heading">注文番号を入力</h3>
					<input className="mb_8" type="text" name="orderNumber" ref={register} />
					<button className="el_btnInv" type="button" onClick={handleClickChangeOrderNumber} disabled={orderNumber === ''}>表示</button>
				</div>
				<table className="bl_table bl_table__headBorderBottom">
					<thead className="bl_table_head">
						<tr>
							<th>注文番号</th>
							<th>メール送信</th>
						</tr>
					</thead>
					{props.targets && <>
						<tbody className="bl_table_body">
							{props.targets.map((target) =>
								<tr key={target.id}>
									<td>
										<ul>
											{target.orders.map(order =>
												<li key={order.number}><a href={toRMSOrderDetailURL(order.number)} target="_blank" rel="noreferrer">{order.number}</a></li>
											)}
										</ul>
									</td>
									<td>
										<label className="el_toggleBtn">
											<input type="checkbox" checked={target.orders.some(order => order.canSendingMail)} onChange={handleChangeCanSendMail(target)} />
											<span />
										</label>
									</td>
								</tr>
							)}
						</tbody>
					</>}
				</table>
			</div>
		</div>
	);
}
export default ExclusionOrderForm;
import { DestType, ItemReward, Reward, RewardRequestData } from '@sasagase/types';
import ja from 'date-fns/locale/ja';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import * as React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { TableVirtuoso } from 'react-virtuoso';
import { getRewardRequests, getRewards, putRewardRequestTransact } from '../../../../api';
import { useAPI, useAPILoad } from '../../../../context';
import { uri } from '../../../../lib';
import { isRetransactableCSVFormat } from '../../../../lib/shopCustom';
import { AllSelector, ListSelector, useListSelector } from '../../../ListSelector';
import RequestDetail from '../RequestDetail';
import RequestTransactModal, { RequestTransactModalCloseEvent } from './RequestTransactModal';
import { saveCSVFile, saveZIPFiles } from './saveCSVFile';
registerLocale('ja', ja);


const DEST_NAME: Record<DestType, string> = {
	'shipping': '同じ',
	'orderer': '注文者住所',
	'other': '指定',
} as const;

interface RequestListContentProps {}
type RequestListContentParams = {
	shopId: string;
}

dayjs.extend(isBetween);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function RequestListContent(props: RequestListContentProps): React.ReactElement {
	const { register, getValues, control } = useForm({
		defaultValues: {
			filterTransactDateStart: null,
			filterTransactDateEnd: null,
		},
	});
	const params = useParams<RequestListContentParams>();
	const callAPI = useAPI();
	const [requests, { reload, setConverter }] = useAPILoad(getRewardRequests(params.shopId ?? ''), {
		onFetch: (data: RewardRequestData[]) => {
			return data;
		},
		onConverter: (all) => {
			const ret = all || [];
			return ret.sort((a,b) => {
				return b.createdDate - a.createdDate;
			});
		},
	});
	const [rewardMap] = useAPILoad<Map<string, Reward>>(getRewards(params.shopId ?? ''), {
		initValue: new Map(),
		onFetch: (data: Record<string, unknown>[]) => {
			return new Map(data.map(obj => Reward.create(obj)).map(reward => [reward.id, reward]));
		},
	});
	const [detailId, setDetailId] = React.useState('');
	const [activeForm, setActiveForm] = React.useState('');
	const { getChecks, reset, refresh } = useListSelector();
	const [isShowTransact, setIsShowTransact] = React.useState(false);

	React.useEffect(() => {
		if (requests && rewardMap) {
			const prevChecks = getChecks();
			const checks = Object.fromEntries(requests.flatMap((req) => {
				const reward = rewardMap.get(req.selectedRewardId);
				return reward instanceof ItemReward && isRetransactableCSVFormat(reward.csvFormat) ? [[req.id, prevChecks[req.id] ?? false]] : [];
			}));
			reset(checks);
		} else {
			refresh();
		}
	}, [requests, rewardMap]);

	React.useEffect(() => {
		const docClickHandler = (ev: any) => {
			if (!(ev.target instanceof HTMLElement) || ev.target.closest("th.bl_table_filterHead") || ev.target.closest("react-datepicker__tab-loop")) {
				return;
			}
			setActiveForm('');
		};
		document.body.addEventListener('click', docClickHandler);
		return () => document.body.removeEventListener('click', docClickHandler);
	}, []);

	const handleClickDetail = (requestId: string) => () => {
		setDetailId(requestId);
	};
	const handleDetailClose = () => {
		setDetailId('');
	};

	const handleClickTransact = async () => {
		if (!requests || !rewardMap) {
			return;
		}
		const checks = getChecks();
		if (Object.values(checks).every(checked => !checked)) {
			alert("対象が選択されておりません");
			return;
		}
		setIsShowTransact(true);
	};

	const handleCloseTransactModal = async (ev: RequestTransactModalCloseEvent) => {
		setIsShowTransact(false);
		if (ev.isCancel) {
			return;
		}

		const checks = getChecks();
		const ids = requests?.filter((req) => checks[req.id]).map(req => req.id) ?? [];

		let result;
		try {
			result = await callAPI(putRewardRequestTransact(params.shopId ?? '', ids, ev.csvFormat, ev.isFormatGroup));
		} catch (err) {
			alert(`特典の受付処理に失敗しました`);
			return;
		}
		if (Object.keys(result.data).length > 0) {
			const csvZipFiles = [];
			for (const [type, data] of Object.entries(result.data)) {
				if (type === 'csv') {
					for (const csv of data) {
						if (ev.isZipFile) {
							csvZipFiles.push(csv);
						} else {
							await saveCSVFile(csv);
						}
					}
				} else if (type === 'sasagase') {
					alert(`${data.orderIds.length} 件の特典をSasagaseに追加しました`);
				}
			}
			if (csvZipFiles.length > 0) {
				const date = new Date(result.headers.date);
				await saveZIPFiles(csvZipFiles, date);
			}

		} else {
			alert('受付処理の対象が存在しませんでした');
		}
		reset();
		reload();
	};

	const handleSubmitConvert = () => {
		setActiveForm('');
		const cond = {
			sort: getValues('sort'),
			orderId: getValues('filterOrderId'),
			rewardId: getValues('filterRewardId'),
			rewardName: getValues('filterRewardName'),
			ordererName: getValues('filterOrdererName'),
			dl: getValues('filterDl'),
			transactDateStart: getValues('filterTransactDateStart') || '',
			transactDateEnd: getValues('filterTransactDateEnd') || '',
		}
		setConverter((all) => {
			let ret = all || [];
			ret = ret.sort((a,b) => {
				if (cond.sort && cond.sort == 'old') {
					return a.createdDate - b.createdDate;
				} else {
					return b.createdDate - a.createdDate;
				}
			});
			if (cond.orderId) {
				ret = ret.filter(req => req.orderNumber == cond.orderId);
			}
			if (cond.rewardId && cond.rewardId !== 'all') {
				ret = ret.filter(req => req.selectedRewardId == cond.rewardId);
			}
			if (cond.rewardName && rewardMap) {
				ret = ret.filter(req => rewardMap.get(req.selectedRewardId)?.name.includes(String(cond.rewardName)));
			}
			if (cond.ordererName) {
				ret = ret.filter(req => req.ordererName.includes(String(cond.ordererName)));
			}
			if (cond.dl) {
				if (cond.dl == '1') {
					ret = ret.filter(req => req.isTransacted);
				} else if (cond.dl == '0') {
					ret = ret.filter(req => !req.isTransacted);
				}
			}
			if (cond.transactDateStart && cond.transactDateEnd) {
				ret = ret.filter(req => {
					if (req.transactDate) {
						const reqTransactDate = dayjs(req.transactDate);
						const dateStart = dayjs(cond.transactDateStart).startOf("day");
						const dateEnd = dayjs(cond.transactDateEnd).endOf("day");
						return reqTransactDate.isBetween(dateStart, dateEnd);
					}
				});
			}
			return ret;
		});
	}
	const handleClickActiveForm = (form: string) => {
		setActiveForm(prev => prev === form ? '' : form);
	}

	const toDateString = (date?: number) => {
		if (!date) {
			return '';
		}
		return dayjs(date).format('YYYY-MM-DD');
	};
	const isActiveForm = (form: string) => {
		return activeForm === form ? 'is_active' : '';
	}

	const checks = getChecks();
	const checked = Object.values(checks).filter(Boolean);

	const toRMSOrderDetailURL = (orderNumber: string) => uri`https://order-rp.rms.rakuten.co.jp/order-rb/individual-order-detail-sc/init?orderNumber=${orderNumber}`;
	return (
		<>
			{detailId &&
				<RequestDetail requestId={detailId} onClose={handleDetailClose} />
			}
			{isShowTransact &&
				<RequestTransactModal onClose={handleCloseTransactModal} />
			}
			<h1 className="el_pageTtl">特典申込受付</h1>
			<p className="el_pageDesc">特典申込受付の確認ができます。また、申込者の送り先情報をダウンロードすることができます。</p>
			<div className="bl_row">
				<div className="bl_col bl_col__12">
					<div className="bl_panel bl_panel__bt">
						<div className="bl_panel_headerFooter">
							<button className="el_btn mr_8" type="button" onClick={handleClickTransact}>送り先情報CSVダウンロード</button>
							<span className="el_selectedCount mr_32">選択中 {checked.length}件</span>
							<span className="el_selectBorderLessWrap">
								<select className="el_selectBorderLess" name="sort" ref={register} onChange={handleSubmitConvert}>
									<option value="new">新着順</option>
									<option value="old">古い順</option>
								</select>
							</span>
						</div>
						<TableVirtuoso
							className="bl_virtual_table bl_table__reception"
							style={{ height: '' /* 指定しないとTableVirtuosoのデフォルト値が設定されるので、空文字を指定してCSSで設定している height を使わせる */ }}
							data={requests || []}
							fixedHeaderContent={() => (
								<tr>
									<th><AllSelector className="el_checkMark" /></th>
									<th>申込日</th>
									<th className={`bl_table_filterHead ${isActiveForm('order')}`}>
										<span className="bl_table_filterHeadTtl" onClick={() => handleClickActiveForm('order')}>注文番号</span>
										<div className="bl_tableSearchForm">
											<p className="bl_tableSearchForm_ttl">検索</p>
											<div className="el_searchInputWrap">
												<input type="text" name="filterOrderId" ref={register} />
												<button className="el_searchBtn" type="button" onClick={handleSubmitConvert} />
											</div>
										</div>
									</th>
									<th className={`bl_table_filterHead ${isActiveForm('reward')}`}>
										<span className="bl_table_filterHeadTtl" onClick={() => handleClickActiveForm('reward')}>プレゼント品・クーポン</span>
										<div className="bl_tableSearchForm">
											<p className="bl_tableSearchForm_ttl">フィルタ</p>
											<div className="el_selectWrap">
												<select name="filterRewardId" ref={register} onChange={handleSubmitConvert}>
													<option value="all">すべて表示</option>
													{rewardMap && <>
														{[...rewardMap.entries()].map(([rewardId, reward]) => 
															<option key={rewardId} value={rewardId}>{reward.name}</option>
														)}
													</>}
												</select>
											</div>
											<p className="bl_tableSearchForm_ttl">検索</p>
											<div className="el_searchInputWrap">
												<input type="text" name="filterRewardName" ref={register} />
												<button className="el_searchBtn" type="button" onClick={handleSubmitConvert} />
											</div>
										</div>
									</th>
									<th className={`bl_table_filterHead ${isActiveForm('orderer')}`}>
										<span className="bl_table_filterHeadTtl" onClick={() => handleClickActiveForm('orderer')}>注文者</span>
										<div className="bl_tableSearchForm">
											<p className="bl_tableSearchForm_ttl">検索</p>
											<div className="el_searchInputWrap">
												<input type="text" name="filterOrdererName" ref={register} />
												<button className="el_searchBtn" type="button" onClick={handleSubmitConvert} />
											</div>
										</div>
									</th>
									<th>配送先</th>
									<th className={`bl_table_filterHead ${isActiveForm('dl')}`}>
										<span className="bl_table_filterHeadTtl" onClick={() => handleClickActiveForm('dl')}>送り先情報DL状況</span>
										<div className="bl_tableSearchForm bl_tableSearchForm__right">
											<p className="bl_tableSearchForm_ttl">フィルタ</p>
											<div className="el_selectWrap">
												<select name="filterDl" ref={register} onChange={handleSubmitConvert}>
													<option value="all">すべて表示</option>
													<option value="1">済</option>
													<option value="0">未</option>
												</select>
											</div>
										</div>
									</th>
									<th className={`bl_table_filterHead ${isActiveForm('transactDate')}`}>
										<span className="bl_table_filterHeadTtl" onClick={() => handleClickActiveForm('transactDate')}>処理日</span>
										<div className="bl_tableSearchForm bl_tableSearchForm__right">
											<p className="bl_tableSearchForm_ttl">期間：開始日</p>
											<div>
												<Controller
													control={control}
													name="filterTransactDateStart"
													render={({ onChange, value }) => (
														<ReactDatePicker
															locale="ja"
															onChange={(date, event) => {
																onChange(date);
																if ((date && getValues('filterTransactDateEnd')) || (date === null && getValues('filterTransactDateEnd') === null)) {
																	handleSubmitConvert();
																} else {
																	event?.stopPropagation();
																}
															}}
															selected={value}
															dateFormat="yyyy/MM/dd"
															isClearable
															/>
													)}
												/>
											</div>
											<p className="bl_tableSearchForm_ttl">期間：終了日</p>
											<div>
												<Controller
													control={control}
													name="filterTransactDateEnd"
													render={({ onChange, value }) => (
														<ReactDatePicker
															locale="ja"
															onChange={(date, event) => {
																onChange(date);
																if ((date && getValues('filterTransactDateStart')) || date === null && getValues('filterTransactDateStart') === null) {
																	handleSubmitConvert();
																} else {
																	event?.stopPropagation();
																}
															}}
															selected={value}
															dateFormat="yyyy/MM/dd"
															isClearable
															/>
													)}
												/>
											</div>
										</div>
									</th>
								</tr>
							)}
							computeItemKey={(_, req) => req.id}
							itemContent={(_, req) => {
								const reward = rewardMap && rewardMap.size > 0 && rewardMap.get(req.selectedRewardId);
								// クーポンは受付完了メールで自動的に処理されるのでチェックさせない
								// 再処理不可能な特典(sasagase)は処理済みならチェックさせない
								const isTransactable = reward instanceof ItemReward && (isRetransactableCSVFormat(reward.csvFormat) || !req.isTransacted);
								return (
									<tr key={req.id}>
										<td>
											{isTransactable ?
												<ListSelector className="el_checkMark" idx={req.id} /> : <input type="checkbox" className="el_checkMark" disabled />
											}
										</td>
										<td>{toDateString(req.createdDate)}</td>
										<td>
											<a href={toRMSOrderDetailURL(req.orderNumber)} target="_blank" rel="noreferrer">{req.orderNumber}</a>
										</td>
										<td>{reward && reward.name}</td>
										<td>{req.ordererName}</td>
										<td>{DEST_NAME[req.destType]}<button className="el_btnTxt el_btnTxt__underLine js_showDetail" type="button" onClick={handleClickDetail(req.id)}>詳細</button></td>
										<td>{req.isTransacted ? '済' : '未'}</td>
										<td>{toDateString(req.transactDate)}</td>
									</tr>
								);
							}}
							totalCount={requests ? requests.length : 0}
						/>
						<p className="el_resultCount">全{requests ? requests.length : '-'}件</p>
						<div className="bl_panel_headerFooter">
							<button className="el_btn mr_8" type="button" onClick={handleClickTransact}>送り先情報CSVダウンロード</button>
							<span className="el_selectedCount">選択中 {checked.length}件</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default RequestListContent;
import { PageInfo, PageParams, RakutenOrder, RewardTarget, RewardTargetsSummary, rewardTargetStatus } from '@sasagase/types';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';
import { getShop, getTargetSummary, getTargetsPagination, putOrderCanSendingMail } from '../../../../api';
import { useAPI } from '../../../../context';
import ReviewUpload from './ReviewUpload';
import TargetList from './TargetList';
import TargetSummary from './TargetSummary';

interface TargetProps {}
type TargetParams = {
	shopId: string;
}

function getPageParams(page=1): PageParams {
	return {
		page: page,
	};
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Target(props: TargetProps): React.ReactElement {
	const callAPI = useAPI();
	const formMethods = useForm();
	const { getValues } = formMethods;
	const params = useParams<TargetParams>();
	const [queryParams,] = useSearchParams();
	const [summary, setSummary] = React.useState<RewardTargetsSummary|null>(null);
	const [targets, setTargets] = React.useState<RewardTarget[]|null>(null);
	const [pageInfo, setPageInfo] = React.useState<PageInfo|null>(null);
	const [checkDate, setCheckDate] = React.useState<number | null>(null);

	React.useEffect(() => {
		if (checkDate) {
			return;
		}

		return callAPI(getShop(params.shopId ?? ''), (err, result) => {
			if (err) {
				throw err;
			}
			const checkDate = result.data.lastReviewCheckDate;
			setCheckDate(checkDate);
		});
	}, [checkDate]);

	React.useEffect(() => {
		if (summary) {
			return;
		}
		return callAPI(getTargetSummary(params.shopId ?? ''), (err, result) => {
			if (err) {
				throw err;
			}
			setSummary(result.data);
		});
	}, [summary]);

	React.useEffect(() => {
		if (targets) {
			return;
		}
		return callAPI(getTargetsPagination(params.shopId ?? '', {}, getPageParams()), (err, result) => {
			if (err) {
				throw err;
			}
			setPageInfo(result.data.pageInfo);
			const targets = result.data.data.map((obj: Record<string, unknown>) => RewardTarget.create(obj));
			setTargets(targets);
		});
	}, [targets]);

	const loadMore = async (reset = false) => {
		let p = 1;
		if (!reset && pageInfo) {
			if (!pageInfo.hasNextPage) {
				return;
			} else {
				p = pageInfo.page + 1;
			}
		}
		const { filterOrderNumber, filterStatus } = getValues();
		const cond = {
			orderNumber: filterOrderNumber,
			status: rewardTargetStatus.includes(filterStatus) ? filterStatus : undefined,	// all=undefined
		};
		return callAPI(getTargetsPagination(params.shopId ?? '', cond, getPageParams(p)), (err, result) => {
			if (err) {
				throw err;
			}
			setPageInfo(result.data.pageInfo);
			const load: RewardTarget[] = result.data.data.map((obj: Record<string, unknown>) => RewardTarget.create(obj));
			if (reset) {
				setTargets(load);
			} else {
				const all = [...(targets || []), ...load];
				setTargets(all);
			}
		});
	};

	const handleSubmitFilter = () => {
		if (!targets) {
			return;
		}
		loadMore(true);
		const { filterOrderNumber, filterStatus } = getValues();
		let filterTargets = [...targets];
		if (filterOrderNumber) {
			filterTargets = filterTargets.filter(target => {
				return target.orders.some(order => order.number == filterOrderNumber);
			});
		}
		if (filterStatus && rewardTargetStatus.includes(filterStatus)) {
			filterTargets = filterTargets.filter(target => target.getStatus() == filterStatus);
		}
		setTargets(filterTargets);
	};

	const handleChangeCanSendMail = async (target: RewardTarget, canSendMail: boolean) => {
		if (!targets) {
			return;
		}

		const changedOrders: RakutenOrder[] = [];
		for (const order of target.orders) {
			const { data: changedOrder } = await callAPI(putOrderCanSendingMail(params.shopId ?? '', order.number, canSendMail));
			changedOrders.push(changedOrder);
		}
		target.orders = changedOrders;

		setTargets([...targets]);
	};

	const handleUploaded = () => {
		setTargets(null);
	};

	return (
		<div className="ly_main__reviewStatus">
			<h1 className="el_pageTtl">レビュー待ち状況</h1>
			<p className="el_pageDesc">レビュー待ち状況やレビューデータの確認ができます。また、レビュー待ち状況一覧よりフォローメールの送信除外設定を行うことができます。</p>
			<div className="bl_row">
				<div className="bl_col bl_col__12">
					<TargetList
						targets={targets}
						pageInfo={pageInfo}
						methods={formMethods}
						loadMore={loadMore}
						onSubmitFilter={handleSubmitFilter}
						onChangeCanSendMail={handleChangeCanSendMail} />
				</div>
			</div>
			<div className="bl_row">
				<div className="bl_col bl_col__8">
					{summary &&
						<TargetSummary summary={summary} reviewCheckDate={checkDate} />
					}
				</div>
				<div className="bl_col bl_col__4">
					<ReviewUpload onUploaded={handleUploaded} />
				</div>
			</div>
		</div>
	);
}
export default Target;
import { RakutenOrder, RewardTarget, ShopEntity } from '@sasagase/types';
import * as React from 'react';
import { getShopEntity, getTargets, putOrderCanSendingMail, putShopEntity } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import ExclusionMailForm from './ExclusionMailForm';
import ExclusionOrderForm from './ExclusionOrderForm';

interface ExclusionProps {}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Exclusion(props: ExclusionProps): React.ReactElement|null {
	const callAPI = useAPI();
	const [state] = useAppState();
	const [targets, setTargets] = React.useState<RewardTarget[]>([]);
	const [excludeMails, setExcludeMail] = React.useState<string[] | null>(null);

	React.useEffect(() => {
		return callAPI(getShopEntity(state.params.shopId), (err, result) => {
			if (err) {
				return;
			}
			const shopEntity = new ShopEntity(result.data);
			setExcludeMail(shopEntity.excludeMailAddress);
		});
	}, [state.params.shopId]);

	const handleChangeOrderNumber = async (orderNumber: string) => {
		try {
			const res = await callAPI(getTargets(state.params.shopId, { orderNumber }));
			const targets = res.data.map((obj: Record<string, unknown>) => RewardTarget.create(obj));
			setTargets(targets);
		} catch (err) {
			return;
		}
	};

	const handleChangeCanSendMail = async (target: RewardTarget, canSendMail: boolean) => {
		if (!targets) {
			return;
		}

		const changedOrders: RakutenOrder[] = [];
		for (const order of target.orders) {
			const { data: changedOrder } = await callAPI(putOrderCanSendingMail(state.params.shopId, order.number, canSendMail));
			changedOrders.push(changedOrder);
		}
		target.orders = changedOrders;

		setTargets([...targets]);
	};

	const handleFilterTarget = async (orderId: string) => {
		setTargets(targets.filter(target => {
			return target.orders.some(order => order.number == orderId);
		}));
	};

	const handleAddExcludeMail = async (mail: string) => {
		if (!excludeMails) {
			return false;
		}

		if (excludeMails.includes(mail)) { return false; }
		const excludeMailAddress = [
			...excludeMails,
			mail,
		];
		try {
			const res = await callAPI(putShopEntity(state.params.shopId, { excludeMailAddress }));
			setExcludeMail(excludeMailAddress);
			return Boolean(res);
		} catch (err) {
			console.log(err);
			return false;
		}
	};

	const handleRemoveExcludeMail = async (mails: string[]) => {
		if (!excludeMails) {
			return false;
		}
		
		const excludeMailAddress = excludeMails.filter((mail: string) => !mails.includes(mail));
		try {
			const res = await callAPI(putShopEntity(state.params.shopId, { excludeMailAddress }));
			setExcludeMail(excludeMailAddress);
			return Boolean(res);
		} catch (err) {
			console.log(err);
			return false;
		}
	};

	if (!excludeMails) {
		return null; // loading
	}
	return (
		<div className="ly_main__exclusion">
			<h1 className="el_pageTtl">メール送信除外設定</h1>
			<p className="el_pageDesc">フォローメールの送信除外設定を行うことができます。  ※楽天マスクアドレスのみに対応</p>
			<div className="bl_row">
				<ExclusionOrderForm
					targets={targets}
					onChangeOrderNumber={handleChangeOrderNumber}
					onChangeCanSendMail={handleChangeCanSendMail}
					onFilterTarget={handleFilterTarget}
					/>
				<ExclusionMailForm
					excludeMails={excludeMails}
					onAddExcludeMail={handleAddExcludeMail}
					onRemoveExcludeMail={handleRemoveExcludeMail}
					/>
			</div>
		</div>
	);
}
export default Exclusion;